@import "./scss/variables";
@import "./scss//style.scss";

* {
  font-family: "Readex Pro", Arial, Helvetica, sans-serif;
}

body {
  margin: 0;
  // font-family: "Readex Pro", -apple-system, BlinkMacSystemFont, "Readex Pro",
  //   "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
  //   "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 300 !important;
}

code {
  font-family: "Readex Pro", source-code-pro, "Readex Pro", Menlo, Monaco,
    Consolas, "Courier New", monospace;
}

.c-main {
  padding-bottom: 2rem;
}

.c-main .panelFront .cardToPay::-webkit-scrollbar {
  -webkit-appearance: none;
}

.sidebar-pepper {
  background: #2c3947 !important;
}

.navbar-item-rounded-pepper {
  font-size: 12pt;
  font-family: "Readex Pro", sans-serif;
  /* margin-right: 15px !important; */
  background: transparent !important;
}

.navbar-item-rounded-pepper-reset {
  border-radius: 0 12px 12px 0;
  font-family: "Readex Pro", sans-serif;
  /* margin-right: 15px !important; */
  background: transparent !important;
}

.navbar-item-rounded-pepper-reset:hover {
  background: transparent !important;
}

.navbar-item-rounded-pepper:hover {
  background: #8875f2 !important;
  font-weight: bold;
}

.c-sidebar .c-sidebar-nav-link.c-active,
.c-sidebar .c-active.c-sidebar-nav-dropdown-toggle {
  background: #8875f2 !important;
  font-weight: bold;
}

.c-sidebar .c-sidebar-nav-link.c-active,
.c-sidebar .c-active.c-sidebar-nav-dropdown-toggle {
  background-color: $background-navBar-selected !important;
}

.c-sidebar-minimized .c-sidebar-nav-link,
.c-sidebar-minimized .c-sidebar-nav-dropdown-toggle,
.c-sidebar-minimized .c-sidebar-nav-dropdown-toggle {
  margin-top: 3px;

  &:hover {
    background-color: $background-navBar-hover !important;
  }
}

.c-sidebar-nav-item {
  width: 100% !important;
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  margin: 0 auto !important;
}

.c-sidebar-nav-link {
  width: 95% !important;
}

.c-sidebar-nav-link:hover {
  width: 95% !important;
}

.sidebar-account {
  width: 100%;
  display: flex;
  align-items: center;
}

.subheader-pepper {
  padding: 0;
  margin: 0;
  text-align: left;
  font-weight: bold;
  font-size: 14pt;
  font-family: "Readex Pro", sans-serif;
}

.subtitle-pepper {
  padding: 0;
  margin: 0;
  text-align: left;
  font-weight: regular;
  font-size: 12pt;
  font-family: "Readex Pro", sans-serif;
  color: #9dafbd;
}

.dropdown-item:hover,
.dropdown-item:focus {
  background-color: #8875f2 !important;
}

.svg-black {
  filter: brightness(0) saturate(100%);
}

.svg-white {
  filter: invert(93%) sepia(7%) saturate(26%) hue-rotate(101deg) brightness(106%) contrast(106%) opacity(80%);
}

.svg-green {
  filter: invert(94%) sepia(11%) saturate(627%) hue-rotate(95deg) brightness(94%) contrast(91%);
}

.bold {
  font-weight: bold;
}

* {
  &::-webkit-scrollbar {
    width: 8px !important;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-track {
    background-color: transparent !important;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: $scroll-grey-light !important;
    min-height: 150px !important;
  }
}

.c-main>.container-fluid,
.c-main>.container-sm,
.c-main>.container-md,
.c-main>.container-lg,
.c-main>.container-xl,
.c-main>.container-xxl {
  padding-left: 30px !important;
  padding-right: 60px !important;
}

.c-main-open>.container-fluid,
.c-main-open>.container-sm,
.c-main-open>.container-md,
.c-main-open>.container-lg,
.c-main-open>.container-xl,
.c-main-open>.container-xxl {
  padding-left: 180px !important;
  padding-right: 60px !important;
  flex-basis: auto;
  flex-shrink: 0;
  flex-grow: 1;
  min-width: 0;
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.modal {
  position: relative !important;
  top: none !important;
  left: none !important;
  height: auto !important;
  overflow: none !important;
  outline: none !important;
}

.fs-10 {
  font-size: 10px !important;
}

.fs-12 {
  font-size: 12px !important;
}

.fs-14 {
  font-size: 14px !important;
}

.fs-15 {
  font-size: 15px;
}

.fs-16 {
  font-size: 16px;
}

.fs-bold {
  font-weight: bold;
}

.w-20 {
  width: 20% !important;
}

.w-30 {
  width: 30% !important;
}

@media only screen and (max-width: 1024px) {
  .c-sidebar-backdrop {
    z-index: 1028 !important;
  }

  .c-sidebar-backdrop.c-show {
    width: 200vw !important;
    height: 200vh !important;
  }

  .pepperApp {
    zoom: 60%;
    height: 167vh !important;
  }

  .c-app {
    min-height: 167vh !important;
  }

  .tableScrollContainer {
    height: 130vh !important;
  }

  .tableScroll {
    height: 124vh !important;
  }

  .nominaTable {
    height: 100vh !important;
  }

  .docTable {
    height: 100vh !important;
  }

  .showSideBar {
    animation: none !important;
  }

  .hideSideBar {
    animation: none !important;
  }

  .incomeOutcomeRow {
    min-width: 100px !important;
  }

}

.accordionRelativePosition {
  position: relative;
}

.innerAccordionEERR>.accordion>.title>.dropdown::before {
  content: "○" !important;
}

:root {
  --toastify-toast-width: auto !important;
}

.accordionEditButton {
  padding: 6px !important;
  font-size: 0.8em !important;
  position: absolute;
  right: 25px;
  cursor: none;
  z-index: 1000;

  &__field {
    right: 35px;
  }
}

.custom-screenshot-btn {
  &::before {
    min-width: 21px;
    min-height: 21px;
  }

  &:hover {
    cursor: pointer;
  }
}

.custom-screenshot-btn-fc {
  position: absolute;
  margin-left: 30px;
  z-index: 4;
  display: flex;
  align-items: center;
}

// BTN SCREENSHOT EERR
.custom-screenshot-btn-eerr {
  position: absolute;
  margin-left: 30px;
  z-index: 4;
  display: flex;
  align-items: center;
}

.custom-btn-container {
  height: 50px !important;
  width: 50px !important;
  background-color: transparent !important;
}

.custom-none-top {
  top: 20% !important;
}

.custom-tooltip {
  border: 2px solid #0099ff;
}

.fs-16 {

  font-size: 16px;
}

.secondary-button {
  background-color: white !important;
  color: #1678C2 !important;
  border: 1px solid #1678C2 !important;
  transition: all 0.5s ease;

  &:hover {
    background-color: #1678c2 !important;
    color: white !important;

  }
}



.fs-14 {
  font-size: 14px;
}

.fw-300 {
  font-weight: 300;
}

.fw-400 {
  font-weight: 400;
}

.fw-500 {
  font-weight: 500;
}

.gap-20 {
  gap: 20px;
}

.h-37 {
  height: 37px;
}

.manageCategoriesTitle {
  border: 1px solid #cccccc;
  background-color: #ededed;
  border-radius: 5px 5px 0px 0px;
  padding: 12px;
  display: flex;
  justify-content: space-between;
  margin: 10px 0px 0px 0px;

  &__iconName {
    gap: 15px;
    display: flex;
  }

  &__areaContainer {
    max-height: 185px;
    overflow-x: auto;
    transition: 1s all;

    &::-webkit-scrollbar {
      border: 1px solid #cccccc;
      border-radius: 0px !important;
      width: 10px !important;
    }
  }

  &__lastArea {
    padding: 12px;
    border: 1px solid #cccccc;
    display: flex;
    justify-content: space-between;
    border-radius: 0px 0px 5px 5px;

    &__icons {
      gap: 15px;
      display: flex;
    }
  }

  &__areas {
    padding: 12px;
    border: 1px solid #cccccc;
    display: flex;
    justify-content: space-between;

    &__icons {
      gap: 15px;
      display: flex;
    }
  }
}

.providers-btn-group {
  max-width: 600px !important;
}

body>div {
  filter: none !important;
}

.default__area-container {
  margin-left: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.transaction__footer {
  margin-top: 20px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.pagination__container {
  display: flex;
  gap: 5px;
}

.small__dropdown {
  font-size: 11px !important;
  height: 33px;
  padding: 0px !important;
}

.small__pagination {
  font-size: 11px !important;
  height: 33px;
  padding: 0px 5px !important;
}