// Here you can add other styles

.custom-provider-container {
  &__providers {
    height: auto;
    overflow: hidden;
    overflow: auto;

  }

  &__nomina {
    max-height: 73vh;
    min-height: 73vh;
    overflow: hidden;
    overflow: auto;
  }

  &__row {
    -moz-transition: height 0.3s !important;
    -ms-transition: height 0.3s !important;
    -o-transition: height 0.3s !important;
    -webkit-transition: height 0.3s !important;
    transition: height 0.3s !important;
    width: 99.5%;
    height: 60px;
    font-size: 12px;
    background-color: white;
    margin-bottom: 5px;
    margin-top: 5px;
    box-shadow: 4px 3px 4px rgba(46, 46, 66, 0.08);
    border-radius: 5px;
    border: 1px solid #e3eef2;

    &__data {
      height: 50px !important;
      display: flex;
      align-items: center;
    }

    &__innerTitle {
      width: 99.5%;
      background-color: #bbe3f1 !important;
      height: 20px !important;
      display: flex;
      align-items: center;
      font-weight: 700;
      font-size: 12px;
      border-radius: 5px;
      text-transform: uppercase;
    }

    &__innerTableData {
      width: 99.5%;
      font-size: 12px;
      height: 32px !important;
      display: flex;
      align-items: center;
      margin-bottom: 5px;

      &__sidebarData {
        width: 99.5%;
        font-size: 10px;
        height: 50px !important;
        display: flex;
        align-items: center;
        margin-bottom: 5px;
      }
    }
  }

  &__title {
    width: 99.5%;
    background-color: #e3eef2 !important;
    height: 30px !important;
    display: flex;
    align-items: center;
    font-weight: 700;
    font-size: 13px;
    border-radius: 5px;
    position: sticky;
    top: 0;
    z-index: 12;
  }
  &__title_cobranza {
    width: 99.5%;
    background-color: #e3eef2 !important;
    height: 30px !important;
    display: flex;
    align-items: center;
    font-weight: 700;
    font-size: 13px;
    border-radius: 5px;
    position: sticky;
    top: 0;
    // z-index: 12;
  }
}

.custom-tab-pane-min-height {
  height: 76vh;
}

.w-5 {
  width: 5% !important;
}

.w-10 {
  width: 10% !important;
}

.w-15 {
  width: 15% !important;
}

.default-collapsed {
  -moz-transition: height 0.3s;
  -ms-transition: height 0.3s;
  -o-transition: height 0.3s;
  -webkit-transition: height 0.3s;
  transition: height 0.3s;
  height: 0;
  overflow: hidden;
}

.expand-class {
  -moz-transition: height 0.3s !important;
  -ms-transition: height 0.3s !important;
  -o-transition: height 0.3s !important;
  -webkit-transition: height 0.3s !important;
  transition: height 0.3s !important;
  height: 80px;
}

.dte-detail {
  -moz-transition: height 0.3s;
  -ms-transition: height 0.3s;
  -o-transition: height 0.3s;
  -webkit-transition: height 0.3s;
  transition: height 0.3s;
  height: 0;
}

.custom-min-width-nomina-modal {
  min-width: 190px !important;
}

.custom-modal-height {
  min-height: 45vh !important;
}

.custom-new-nomina-height {
  min-height: 12vh;
}

.created-nomina-row {
  box-shadow: 4px 3px 4px #2e2e4214 !important;
  border-radius: 5px !important;
  border: 1px solid #e3eef2 !important;
}

.updating-nomina-row {
  opacity: 0.5;
}

.cursor-default {
  cursor: default !important;
}

.loading-table {
  z-index: 1;
  left: 50%;
  bottom: 3%;
}

.hover-btn {
  box-shadow: 0 0 0 1px #2185d0 inset !important;
  background-color: transparent !important;
  transition: .3s;

  & div {
    color: #2185d0;
    font-size: 1rem !important;
    font-style: normal;
    font-weight: 400;
  }

  &:hover {
    transition: .3s;
    background-color: #2185d0 !important;

    & div {
      color: #FFFFFF;
    }
  }
}

.custom-disabled-btn {
  opacity: .3;
}

.custom-nomina-table-password {
  border: 1px solid #e3eef2;
  border-radius: 5px;
  box-shadow: 4px 3px 4px rgba(46, 46, 66, 0.08);
}

@for $i from 1 through 100 {
  .w-#{$i} {
    $width: $i * 1%;
    width: $width !important;
  }
}